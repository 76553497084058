import {atom, useSetAtom} from 'jotai';
import {useDebounceValue} from 'usehooks-ts';
import {useQuery} from '@tanstack/react-query';
import {useEffect, useMemo, useState} from 'react';
import fetcher from 'src/utils/fetcher';

export const searchUsedState = atom<boolean>(false);

type SearchState = {
  results: HumboSearchResult[];
  setText: (text: string) => void;
  text: string;
  isLoading: boolean;
  reset: () => void;
};

const getHref = (result: HumboSearchResult) => {
  if (result.type === 'country') return `/${result.countryCode}`;
  if (result.type === 'listicle') return `/humbo/lists/${result.nameId}`;

  return `/${result.countryCode}/${result.nameId}`;
};

type Options = {
  resultsFilter?: (result: HumboSearchResult) => boolean;
};

const useSearch = ({resultsFilter = () => true}: Options = {}): SearchState => {
  const [text, setTextState] = useState('');
  const setSearchUsed = useSetAtom(searchUsedState);
  const [queryText] = useDebounceValue(text, 100);

  const {data = [], isLoading} = useQuery<HumboSearchResult[]>({
    queryKey: ['search_autocomplete', queryText],
    queryFn: () => fetcher({url: '/api/search/autocomplete', query: {text: queryText}}),
    enabled: Boolean(queryText),
    placeholderData: (previousData) => previousData,
    gcTime: 1000 * 60 // 1 minute
  });

  const results: HumboSearchResult[] = useMemo(() => {
    if (!text || !data) return [];

    return data.filter(resultsFilter).map((r) => ({...r, href: getHref(r)}));
  }, [text, data]);

  const setText = (text: string) => {
    setTextState(text);
    setSearchUsed(Boolean(text));
  };

  const reset = () => setText('');

  useEffect(() => {
    return () => setSearchUsed(false);
  }, []);

  return {
    results,
    setText,
    text,
    isLoading: isLoading && Boolean(text),
    reset
  };
};

export default useSearch;
