'use client';

import {Command} from 'cmdk';
import dynamic from 'next/dynamic';
import {twMerge} from 'tailwind-merge';
import {useEffect, useRef} from 'react';
import {usePathname} from 'next/navigation';
import {closeLine, search2Line} from 'src/utils/icons';
import useSearch from 'src/hooks/useSearch';
import Tooltip from '../common/Tooltip';
import BaseButton from '../BaseButton';
import RemixIcon from '../RemixIcon';

const SearchResults = dynamic(() => import('./SearchResults'));

type Props = {
  className?: string;
  onResultSelect?: (result: HumboSearchResult) => void;
  resultsFilter?: (result: HumboSearchResult) => boolean;
  placeholder?: string;
  hideKeyboardHint?: boolean;
  autoFocus?: boolean;
};

const Search = ({className, onResultSelect, resultsFilter, placeholder, hideKeyboardHint, autoFocus}: Props) => {
  const pathname = usePathname();
  const inputRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  const {setText, text, results, isLoading, reset} = useSearch({resultsFilter});

  useEffect(() => {
    const listener = (e: any) => {
      // Focus search on '/'
      if (e.key === '/') {
        if (/^(?:input|textarea|select|button)$/i.test(e.target.tagName)) return;

        inputRef.current?.focus();
      }

      // Close search on Escape
      if (e.key === 'Escape' && document.activeElement === inputRef.current) {
        reset();
        inputRef.current?.blur();
      }
    };

    window.addEventListener('keyup', listener, {passive: true});

    return () => window.removeEventListener('keyup', listener);
  }, []);

  useEffect(() => {
    if (!resultsRef.current || !results.length) return;

    // Scroll results to top on change
    resultsRef.current.scrollTop = 0;
  }, [results]);

  useEffect(() => {
    if (results.length) reset();
    inputRef.current?.blur();
  }, [pathname]);

  return (
    <Command
      label="Search"
      shouldFilter={false}
      className={twMerge([
        'peer group bg-white z-10 dark:bg-[rgb(33_26_36_/_1)] dark:shadow-[0_-1px_0_0_rgb(255_255_255_/_0.12),0_2px_4px_0_rgb(16_11_19_/_0.6)] shadow-[0_0_0_1px_rgb(23_10_37_/_0.07),0_2px_5px_0_rgb(23_10_37_/_0.03)] absolute flex flex-col items-center focus-within:shadow-[0_0_0_1px_rgb(23_10_37_/_0.07),0_3px_5px_rgb(23_10_37_/_0.02),0_3px_16px_rgb(23_10_37_/_0.04),0_20px_50px_rgb(23_10_37_/_0.07)] focus-within:[&_.slash-hotkey]:hidden rounded-[22px] [&_input]:focus:animate-[appear_0.001s]',
        className
      ])}
    >
      <div className="relative w-full h-[44px]">
        <RemixIcon
          icon={search2Line}
          size={20}
          className="absolute top-[12px] left-[16px] pointer-events-none"
          color="var(--gray-text)"
        />

        {text ? (
          <div className="absolute right-3 h-full flex items-center">
            <BaseButton className="p-0" onPress={reset}>
              <div className="flex justify-center items-center w-[28px] h-[28px] rounded-full btn-bg">
                <RemixIcon icon={closeLine} color="var(--gray-text)" size={18} />
              </div>
            </BaseButton>
          </div>
        ) : null}

        {!text && !hideKeyboardHint ? (
          <Tooltip content="Press '/' to start searching">
            <div className="absolute right-0 px-2 mr-2 h-full flex items-center max-sm:hidden">
              <BaseButton className="slash-hotkey p-0 relative flex justify-center items-center w-5 h-5 text-[12px] font-semibold rounded-md bg-(--button-bg-hover) shadow-[0_1px_0_0_rgb(23_10_37_/_0.1)] cursor-default">
                /
              </BaseButton>
            </div>
          </Tooltip>
        ) : null}

        {text ? null : (
          <div className="absolute w-full mx-auto top-[50%] translate-y-[-50%] pointer-events-none text-(--gray-text) text-center">
            <span className="max-[500px]:hidden ml-3">{placeholder || 'Find places like Tokyo or Japan'}</span>
            <span className="hidden max-[500px]:inline ml-3">{placeholder || 'Search for places'}</span>
          </div>
        )}

        <Command.Input
          ref={inputRef}
          autoFocus={autoFocus}
          className="search-input w-full h-full rounded-full pl-[44px] placeholder-shown:pr-[44px] leading-tight text-lg font-semibold bg-transparent"
          value={text}
          data-hl-record="true"
          onValueChange={(value: string) => setText(value)}
        />
      </div>

      <Command.List
        ref={resultsRef}
        className="top-14 max-h-[400px] w-full overscroll-contain overflow-auto transition-[height] duration-200"
        style={{height: 'min(320px, var(--cmdk-list-height))'}}
      >
        {text ? (
          <SearchResults
            results={results}
            text={text}
            isLoading={isLoading}
            reset={reset}
            onResultSelect={onResultSelect}
          />
        ) : null}
      </Command.List>
    </Command>
  );
};

export default Search;
