'use client';

import {useState} from 'react';
import dynamic from 'next/dynamic';
import {addCircleLine, bucket, earthLine, roadMapFill, trophyLine} from 'src/utils/icons';
import type {NavButtonProps} from './NavButtons';
import useUser from 'src/hooks/useUser';
import NavButtons from './NavButtons';

const AuthModal = dynamic(() => import('../auth/AuthModal'));

const AppNavButtons = () => {
  const {user} = useUser();

  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const navButtons: NavButtonProps[] = [
    user ? {label: 'Lists', href: `/${user?.nameId}/lists`, icon: bucket, isVisible: true} : null,
    user
      ? {
          label: 'Visited',
          href: `/${user?.nameId}/map`,
          icon: earthLine,
          isVisible: true,
          labelComponent: (
            <>
              {'usedVisitedFeature' in user ? null : (
                <span className="bg-[#DF5531] z-1 text-white leading-none py-[3px] px-1 text-[12px] rounded-md -my-2 shadow-[inset_0px_1px_1px_rgba(255,255,255,0.2),0px_1px_3px_rgba(101,39,131,0.3),0px_5px_11px_rgba(101,39,131,0.3)]">
                  New
                </span>
              )}

              <span>Visited</span>
            </>
          )
        }
      : null,
    !user ? {label: 'Top 100', href: '/top100', icon: trophyLine, isVisible: true} : null,
    !user ? {label: 'Visited', href: '/visited', icon: roadMapFill, isVisible: true} : null,
    !user ? {label: 'Join', onPress: () => setIsJoinModalOpen(true), icon: addCircleLine, isVisible: true} : null
  ].filter(Boolean);

  return (
    <>
      <NavButtons items={navButtons} />
      {user ? null : (
        <>
          <AuthModal initialView="join" isOpen={isJoinModalOpen} setIsOpen={setIsJoinModalOpen} />
          <AuthModal initialView="login" isOpen={isLoginModalOpen} setIsOpen={setIsLoginModalOpen} />
        </>
      )}
    </>
  );
};

export default AppNavButtons;
