import {twMerge} from 'tailwind-merge';
import {OverlayArrow, Tooltip as AriaTooltip, TooltipTrigger} from 'react-aria-components';

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  disabled?: boolean;
  delayMs?: number;
  closeDelayMs?: number;
};

const Tooltip = ({children, content, disabled, delayMs = 0, closeDelayMs = 50}: Props) => {
  if (disabled) return children;

  return (
    <TooltipTrigger delay={delayMs} closeDelay={closeDelayMs}>
      {children}

      <AriaTooltip
        className={twMerge(
          'bg-(--purple) text-sm leading-tight rounded-md text-white px-[10px] py-[6px] max-w-[220px] transform-gpu data-entering:animate-tooltip-appear data-exiting:animate-tooltip-appear-reverse drop-shadow-[0px_6px_18px_rgba(101,39,131,0.3)]',
          'data-[placement=top]:[--origin:translateY(4px)] data-[placement=top]:mb-2 data-[placement=bottom]:[--origin:translateY(-4px)] data-[placement=bottom]:mt-2 data-[placement=right]:[--origin:translateX(-4px)] data-[placement=right]:ml-2 data-[placement=left]:[--origin:translateX(4px)] data-[placement=left]:mr-2'
        )}
      >
        <OverlayArrow className="data-[placement=bottom]:[&>svg]:rotate-180 data-[placement=right]:[&>svg]:rotate-90 data-[placement=left]:[&>svg]:-rotate-90 fill-(--purple)">
          <svg width={8} height={8} viewBox="0 0 8 8" aria-hidden="true">
            <path d="M0 0 L4 4 L8 0" />
          </svg>
        </OverlayArrow>

        {content}
      </AriaTooltip>
    </TooltipTrigger>
  );
};

export default Tooltip;
